export const texts = {
  langTitleEn: { en: 'en', pt: 'en' },
  langTitlePt: { en: 'pt', pt: 'pt' },

  //nav
  navHome: { en: 'Home', pt: 'Início' },
  navBlog: { en: 'Blog', pt: 'Blog' },
  navProjects: { en: 'Projects', pt: 'Projetos' },
  navContact: { en: 'Contact', pt: 'Contato' },

  linkHome: { en: '/', pt: '/' },
  linkHomeHome: { en: '/inicio', pt: '/inicio' },
  linkBlog: { en: '/blog', pt: '/blog' },
  linkProjects: { en: '/projetos', pt: '/projetos' },
  linkContact: { en: '/contato', pt: '/contato' },

  //home
  contentHome1: {
    en: 'Bruno Ravanello',
    pt: 'Bruno Ravanello',
  },
  contentHome2: {
    en: 'Software Developer | Full Stack',
    pt: 'Desenvolvedor de Software | Full Stack',
  },
  contentHome3: {
    en: 'How can I help you today?',
    pt: 'Como posso te ajudar hoje?',
  },
  contentHome4: {
    en: 'Services',
    pt: 'Serviços',
  },
  techsHome4: {
    en: 'Main technologies',
    pt: 'Principais tecnologias que utilizo',
  },
  contentHome5: {
    en: 'Web and apps',
    pt: 'Web e aplicativos',
  },
  contentHome6: {
    en: 'Systems',
    pt: 'Sistemas',
  },
  contentHome7: {
    en: 'E-commerce',
    pt: 'E-commerce',
  },
  contentHome8: {
    en: 'Full stack development and maintenance',
    pt: 'Desenvolvimento e manutenção full stack',
  },

  //projects
  contentProjectsTitle: {
    en: 'Projects',
    pt: 'Projetos',
  },
  contentProjectsIntro: {
    en: 'Some projects made.',
    pt: 'Confira alguns projetos realizados.',
  },

  //contact
  contentContactTitle: {
    en: 'Contact',
    pt: 'Contato',
  },
  contentContactIntro: {
    en: 'Contact me.',
    pt: 'Entre em contato comigo.',
  },
  contentContactSent: {
    en: 'Message sent successfully! I will contact you soon.',
    pt: 'Mensagem enviada com sucesso! Logo entrarei em contato com você.',
  },
  contentContactBack: {
    en: 'Back to home page',
    pt: 'Voltar ao início',
  },
  contentContactError: {
    en: 'Error sending data. Check the fields and try again',
    pt: 'Erro ao enviar os dados. Verifique os campos e tente novamente',
  },
  contentContactName: {
    en: 'Name',
    pt: 'Nome',
  },
  contentContactEmail: {
    en: 'E-mail',
    pt: 'E-mail',
  },
  contentContactPhone: {
    en: 'Phone',
    pt: 'Telefone',
  },
  contentContactMessage: {
    en: 'Message',
    pt: 'Mensagem',
  },
  contentContactNameHolder: {
    en: 'Enter your name',
    pt: 'Insira o seu nome',
  },
  contentContactEmailHolder: {
    en: 'Enter your e-mail',
    pt: 'Insira um e-mail',
  },
  contentContactPhoneHolder: {
    en: 'Enter your phone number',
    pt: 'Insira um número de telefone',
  },
  contentContactMessageHolder: {
    en: 'Write your message',
    pt: 'Escreva sua mensagem aqui',
  },
  contentContactNameError: {
    en: 'Enter your name',
    pt: 'Informe o seu nome',
  },
  contentContactEmailError: {
    en: 'Enter a valid e-mail',
    pt: 'Informe um e-mail válido',
  },
  contentContactPhoneError: {
    en: 'Enter a valid phone number',
    pt: 'Informe um telefone válido',
  },
  contentContactMessageError: {
    en: 'Insert a message',
    pt: 'Insira uma mensagem',
  },
  contentContactSend: {
    en: 'Send',
    pt: 'Enviar',
  },
  //blog
  contentBlogTitle: {
    en: 'Blog',
    pt: 'Blog',
  },
  contentBlogSearchWord: {
    en: 'Search',
    pt: 'Busca',
  },
  contentBlogSearchCategory: {
    en: 'Select a category',
    pt: 'Selecione uma categoria',
  },
  contentBlogPostTitle: {
    en: 'Title',
    pt: 'Título',
  },
  contentBlogPostCategory: {
    en: 'Category',
    pt: 'Categoria',
  },
  contentBlogPostAccess: {
    en: 'See more',
    pt: 'Acessar',
  },
  contentBlogPerPage: {
    en: 'Per page:',
    pt: 'Por página:',
  },
  contentBlogOrderBy: {
    en: 'Order by:',
    pt: 'Ordenar por:',
  },
  contentBlogPageCurrent: {
    en: 'You are on page ',
    pt: 'Você está na página',
  },
  contentBlogPageCount: {
    en: 'of',
    pt: 'de',
  },
  contentBlogNoResults: {
    en: 'No results found.',
    pt: 'Nenhum resultado encontrado.',
  },
  contentBlogRecording: {
    en: 'Recording..',
    pt: 'Gravando..',
  },
  //post
  contentPostLinkBack: {
    en: 'go back',
    pt: 'voltar',
  },
  contentPostComments: {
    en: 'Comments',
    pt: 'Comentários',
  },
  contentPostShare: {
    en: 'Share',
    pt: 'Compartilhar',
  },
  contentPostMoreCategories: {
    en: 'Categories',
    pt: 'Categorias',
  },
  contentPostPrev: {
    en: 'Previous Post',
    pt: 'Post Anterior',
  },
  contentPostNext: {
    en: 'Next Post',
    pt: 'Próximo Post',
  },
};
