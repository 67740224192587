import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { texts } from '../../texts';
import './Projects.css';
import Skeleton from 'react-loading-skeleton';
import FadeIn from 'react-fade-in';
import 'react-loading-skeleton/dist/skeleton.css';
import max from '../../max.png';

function Projects(props) {
  const [projects, setProjects] = useState([]);
  const [displaySkeletons, setDisplaySkeletons] = useState('block');
  const [displayCarousels, setDisplayCarousels] = useState(false);
  const [loadingShow, setLoadingShow] = useState('inline-block');

  useEffect(() => {
    window.scrollTo(0, 0);

    async function getProjects() {
      setTimeout(function () {
        props.handleMenuHide();
      }, 300);

      const apiProjects = 'https://api.brunoravanello.com.br/api/projects';

      const resProjects = await axios.get(apiProjects);

      setProjects(resProjects.data);

      setDisplaySkeletons('none');
      setDisplayCarousels(true);
      setLoadingShow('none');
    }

    getProjects();
  }, []);

  return (
    <section className={'projects ' + (props.wide ? '' : 'sectionWide')}>
      <div
        style={{ maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <div className="loader" style={{ display: loadingShow }}>
          <div className="loaderBar"></div>
        </div>

        <h1 className="titleInside">Bruno Ravanello</h1>

        <FadeIn>
          <h2>{texts['contentProjectsTitle'][props.lang]}</h2>
        </FadeIn>

        <p>{texts['contentProjectsIntro'][props.lang]}</p>

        <div
          className="skeletonsProjectsWrapp"
          style={{ display: displaySkeletons }}
        >
          <div className="skeletonsProjectsBox">
            <Skeleton className="skeletonsProjects" />
          </div>
          <div className="skeletonsProjectsBox">
            <Skeleton className="skeletonsProjects" />
          </div>
          <div className="skeletonsProjectsBox">
            <Skeleton className="skeletonsProjects" />
          </div>
          <div className="skeletonsProjectsBox">
            <Skeleton className="skeletonsProjects" />
          </div>
        </div>

        {projects.length > 0 &&
          displayCarousels &&
          projects.map((project) => (
            <div className="project" key={project.id}>
              <p className="projectLegend">
                {props.lang === 'pt' ? project.title : project.title_en}
              </p>
              <a
                href={
                  'https://api.brunoravanello.com.br/storage/' + project.image
                }
                target="_blank"
                rel="noreferrer"
              >
                <div className="imgHoverBg">
                  <img
                    className="imgHover"
                    src={
                      'https://api.brunoravanello.com.br/storage/' +
                      project.image
                    }
                    alt={props.lang === 'pt' ? project.title : project.title_en}
                  />
                  <img
                    src={max}
                    alt={props.lang === 'pt' ? project.title : project.title_en}
                    className="imgHoverMax"
                  />
                </div>
              </a>
            </div>
          ))}

        {!projects.length && displayCarousels && (
          <b>
            Ops! Ocorreu um erro no carregamento da página. Tente novamente.
          </b>
        )}
      </div>
    </section>
  );
}

export default Projects;
