import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';
import { FaPaperPlane } from 'react-icons/fa';
import FadeIn from 'react-fade-in';
import { texts } from '../../texts';
import './Contact.css';

function Contact(props) {
  const recaptchaRef = React.useRef();

  const [name, setName] = useState({ value: '', error: false });
  const [email, setEmail] = useState({ value: '', error: false });
  const [phone, setPhone] = useState({ value: '', error: false });
  const [message, setMessage] = useState({ value: '', error: false });
  const [showOverlay, setShowOverlay] = useState('none');
  const [showSent, setShowSent] = useState('none');
  const [showError, setShowError] = useState('none');
  const [showForm, setShowForm] = useState('block');

  function handleName(e) {
    setName({ value: e.target.value });
  }

  function handleEmail(e) {
    setEmail({ value: e.target.value });
  }

  function handlePhone(e) {
    setPhone({ value: e.target.value });
  }

  function handleMessage(e) {
    setMessage({ value: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let error = 0;

    if (!name.value) {
      error++;
      setName({ error: true });
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = emailRegex.test(String(email.value).toLowerCase());
    if (!email.value || !emailValid) {
      error++;
      setEmail({ error: true });
    }

    if (!phone.value) {
      error++;
      setPhone({ error: true });
    }

    if (!message.value) {
      error++;
      setMessage({ error: true });
    }

    if (!error) {
      setShowError('none');
      setShowOverlay('block');

      const recaptchaToken = await recaptchaRef.current.executeAsync();

      const headers = {
        'Content-Type': 'application/json',
      };

      const res = await axios.post(
        'https://api.brunoravanello.com.br/api/message',
        {
          name: name.value,
          email: email.value,
          phone: phone.value,
          message: message.value,
          recaptcha: recaptchaToken,
        },
        {
          headers: headers,
        }
      );

      if (res.data.sent) {
        setShowForm('none');
        setShowSent('block');
      } else {
        window.grecaptcha.reset();
        setShowError('block');
      }

      setTimeout(function () {
        setShowOverlay('none');
      }, 300);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(function () {
      props.handleMenuHide();
    }, 300);
  }, []);

  return (
    <section className={'contact ' + (props.wide ? '' : 'sectionWide')}>
      <div
        style={{ maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <h1 className="titleInside">Bruno Ravanello</h1>

        <div style={{ display: showOverlay }} className="overlay">
          <div className="overlay__inner">
            <div className="overlay__content">
              <div className="load"></div>
            </div>
          </div>
        </div>

        <FadeIn>
          <h2>{texts['contentContactTitle'][props.lang]}</h2>
        </FadeIn>

        <p style={{ display: showForm }}>
          {texts['contentContactIntro'][props.lang]}
        </p>

        <div className="errorSent" style={{ display: showError }}>
          <p>{texts['contentContactError'][props.lang]}</p>
          <a rel="noopener noreferrer" href={texts['linkHomeHome'][props.lang]}>
            {texts['contentContactBack'][props.lang]}
          </a>
        </div>

        <div className="confirmSent" style={{ display: showSent }}>
          <p>{texts['contentContactSent'][props.lang]}</p>
          <a rel="noopener noreferrer" href={texts['linkHomeHome'][props.lang]}>
            {texts['contentContactBack'][props.lang]}
          </a>
        </div>

        <form style={{ display: showForm }}>
          <TextField
            className="inputs"
            inputProps={{ maxLength: 255 }}
            helperText={
              name.error ? texts['contentContactNameError'][props.lang] : ''
            }
            error={name.error}
            onChange={(e) => handleName(e)}
            placeholder={texts['contentContactNameHolder'][props.lang]}
            label={texts['contentContactName'][props.lang]}
            variant="outlined"
            required
          />
          <TextField
            className="inputs inputEmail"
            inputProps={{ maxLength: 255 }}
            helperText={
              email.error ? texts['contentContactEmailError'][props.lang] : ''
            }
            error={email.error}
            onChange={(e) => handleEmail(e)}
            type="email"
            placeholder={texts['contentContactEmailHolder'][props.lang]}
            label={texts['contentContactEmail'][props.lang]}
            variant="outlined"
            required
          />

          <InputMask
            mask="(99) 999999999"
            value={phone.value}
            onChange={(e) => handlePhone(e)}
          >
            {() => (
              <TextField
                className="inputs inputPhone"
                inputProps={{ maxLength: 20 }}
                helperText={
                  phone.error
                    ? texts['contentContactPhoneError'][props.lang]
                    : ''
                }
                error={phone.error}
                type="tel"
                placeholder={texts['contentContactPhoneHolder'][props.lang]}
                label={texts['contentContactPhone'][props.lang]}
                variant="outlined"
                required
              />
            )}
          </InputMask>

          <TextField
            className="textAreas"
            helperText={
              message.error
                ? texts['contentContactMessageError'][props.lang]
                : ''
            }
            error={message.error}
            onChange={(e) => handleMessage(e)}
            label={texts['contentContactMessage'][props.lang]}
            multiline
            rows="4"
            placeholder={texts['contentContactMessageHolder'][props.lang]}
            variant="outlined"
            required
          />
          <ReCAPTCHA
            sitekey="6Lc5rGMdAAAAALnki7quOv4ZpuIfz_nzNsNJUDmm"
            ref={recaptchaRef}
            size="invisible"
          />
          <Button
            className="buttons"
            onClick={(e) => handleSubmit(e)}
            type="submit"
            variant="contained"
          >
            {texts['contentContactSend'][props.lang]}&nbsp;&nbsp;
            <FaPaperPlane />
          </Button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
