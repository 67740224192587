import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';
import Parser from 'react-html-parser';
import { FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { texts } from '../../texts';
import Skeleton from 'react-loading-skeleton';
import { DiscussionEmbed } from 'disqus-react';
import { ShareSocial } from 'react-share-social';
import QRCode from 'qrcode.react';
import 'react-loading-skeleton/dist/skeleton.css';
import './Post.css';

function Post(props) {
  const urlPath = window.location.pathname;
  const urlLast = urlPath.split('/').pop();

  const [linkBackShow, setLinkBackShow] = useState('linkBackShow');
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [skeletonShow, setSkeletonShow] = useState('block');
  const [skeletonCatShow, setSkeletonCatShow] = useState('block');
  const [loadingShow, setLoadingShow] = useState('inline-block');

  const styleShareSocial = {
    padding: '0',
    position: 'relative',
    top: '-12px',
    backgroundColor: 'transparent',
  };

  function stopLoading(content, categories = false) {
    if (skeletonShow === 'block' && content) {
      setSkeletonShow('none');
    }

    if (skeletonCatShow === 'block' && categories) {
      setSkeletonCatShow('none');
    }
  }

  async function getPost() {
    const apiPost = 'https://api.brunoravanello.com.br/api/post';

    const resPost = await axios.get(apiPost, {
      params: {
        slug: urlLast,
      },
    });

    if (!resPost.data) {
      window.location.href = '/blog?s=&c=&pp=10&p=1&o=desc';
    }

    stopLoading(true, false);

    setPost(resPost.data);
  }

  async function getCategories() {
    const apiCategories = 'https://api.brunoravanello.com.br/api/category';

    const resCategories = await axios.get(apiCategories, {});

    stopLoading(false, true);

    setCategories(resCategories.data);
  }

  useEffect(() => {
    if (skeletonShow === 'none' && skeletonCatShow === 'none') {
      setLoadingShow('none');
    }
  }, [skeletonShow, skeletonCatShow]);

  useEffect(() => {
    props.setNavBack(true);

    window.scrollTo(0, 0);

    setTimeout(function () {
      props.handleMenuHide();
    }, 300);

    setTimeout(function () {
      setLinkBackShow('');
    }, 1000);

    getPost();

    getCategories();
  }, []);

  return (
    <section className={'post ' + (props.wide ? '' : 'sectionWide')}>
      <div
        style={{ maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <div className="loader" style={{ display: loadingShow }}>
          <div className="loaderBar"></div>
        </div>

        <h1 className="titleInside">Bruno Ravanello</h1>

        <NavLink
          className={'linkBack ' + linkBackShow}
          to={texts['linkBlog'][props.lang] + props.blogFilter}
        >
          <FaArrowLeft className="linkBackFa" />{' '}
          {texts['contentPostLinkBack'][props.lang]}
        </NavLink>

        <div className="content">
          <div className="skeletonWrapp" style={{ display: skeletonShow }}>
            <Skeleton className="skeletonsBlog skeletonTitle" />
          </div>

          {post && post.image ? (
            <img
              className="postImg"
              src={'https://api.brunoravanello.com.br/storage/' + post.image}
            />
          ) : (
            ''
          )}

          <h2 className="postH2">{post ? post.title : ''}</h2>

          <div className="skeletonWrapp" style={{ display: skeletonShow }}>
            <Skeleton className="skeletonsBlog skeletonBody" />
          </div>

          <div className="postContent">{post ? Parser(post.txt) : ''}</div>

          {post ? (
            <div>
              {post.slug_prev ? (
                <a
                  className="linkss"
                  href={texts['linkBlog'][props.lang] + '/' + post.slug_prev}
                >
                  {texts['contentPostPrev'][props.lang]}
                </a>
              ) : (
                ''
              )}
              {post.slug_next ? (
                <a
                  className="linkss"
                  href={texts['linkBlog'][props.lang] + '/' + post.slug_next}
                >
                  {texts['contentPostNext'][props.lang]}
                </a>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>

        <br />

        <h4 className="otherOptions">
          {texts['contentPostComments'][props.lang]}
        </h4>

        <div className="skeletonWrapp" style={{ display: skeletonShow }}>
          <Skeleton className="skeletonsBlog skeletonCats" />
        </div>

        {post && (
          <div className="disqus">
            <DiscussionEmbed
              shortname="bruno-ravanello-desenvolvedor-de-software"
              config={{
                url: 'https://brunoravanello.com.br/blog/' + post.slug,
                identifier: post.id.toString(),
                title: post.title,
                language: 'pt_BR',
              }}
            />
          </div>
        )}

        <br />

        <h4 className="otherOptions">
          {texts['contentPostShare'][props.lang]}
        </h4>

        <div className="skeletonWrapp" style={{ display: skeletonShow }}>
          <Skeleton className="skeletonsBlog skeletonCats" />
        </div>

        {post && (
          <div className="shareSocial">
            <div className="socials">
              <ShareSocial
                style={styleShareSocial}
                url={'https://brunoravanello.com.br/blog/' + post.slug}
                socialTypes={['facebook', 'twitter', 'linkedin']}
              />
            </div>

            <div className="qrCode">
              <QRCode
                size={64}
                value={'https://brunoravanello.com.br/blog/' + post.slug}
              />
            </div>
          </div>
        )}

        <br />

        <h4 className="otherOptions">
          {texts['contentPostMoreCategories'][props.lang]}
        </h4>

        <div className="skeletonWrapp" style={{ display: skeletonCatShow }}>
          <Skeleton className="skeletonsBlog skeletonCats" />
        </div>

        {categories.length > 0 && (
          <div className="moreCats">
            {categories.map((category) => (
              <NavLink
                key={category.id}
                className="linkss"
                to={
                  texts['linkBlog'][props.lang] +
                  '?s=&c=' +
                  category.slug +
                  '&pp=10&p=1&o=desc'
                }
              >
                {category.name}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Post;
