import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';
import Home from './pages/home/Home';
import Projects from './pages/projects/Projects';
import Blog from './pages/blog/Blog';
import Post from './pages/post/Post';
import Contact from './pages/contact/Contact';
import {
  FaAlignLeft,
  FaRegLightbulb,
  FaLightbulb,
  FaWhatsapp,
} from 'react-icons/fa';
import { texts } from './texts';
import './App.css';

function App() {
  const hasLang = localStorage.getItem('storedLang');
  const [lang, setLang] = useState(hasLang ? hasLang : 'pt');
  const [menuShow, setMenuShow] = useState(true);
  const [lightShow, setLightShow] = useState(true);
  const [blogRemount, setBlogRemount] = useState(1);
  const [appShow, setAppShow] = useState(false);

  const [blogFilter, setBlogFilter] = useState('?s=&c=&pp=10&p=1&o=desc');
  const [navBack, setNavBack] = useState(false);

  function handleLang(e, langInfo) {
    e.preventDefault();
    localStorage.removeItem('storedLang');
    localStorage.setItem('storedLang', langInfo);
    setLang(langInfo);
  }

  function handleMenuShow() {
    setMenuShow(menuShow ? false : true);
  }

  function handleMenuHide() {
    setMenuShow(false);
  }

  function handleLightShow() {
    setLightShow(lightShow ? false : true);
  }

  function handleBlogRemount() {
    setBlogRemount(blogRemount + 1);
  }

  useEffect(() => {
    setTimeout(function () {
      setAppShow(true);
    }, 2000);
  }, []);

  return (
    <Router>
      {!appShow && <div className="appCover"></div>}

      <div className={'app ' + (lightShow ? 'appLightShow' : 'appDarkShow')}>
        <div className="contentWrapp">
          <nav className="langs">
            <span
              className={lang === 'pt' ? 'langSelected' : ''}
              onClick={(e) => handleLang(e, 'pt')}
            >
              {texts['langTitlePt'][lang]}
            </span>
            <span
              className={lang === 'en' ? 'langSelected' : ''}
              onClick={(e) => handleLang(e, 'en')}
            >
              {texts['langTitleEn'][lang]}
            </span>
          </nav>

          <FaAlignLeft
            className={'btnMenu ' + (menuShow ? 'btnMenuShow' : 'btnMenuHide')}
            onClick={() => handleMenuShow()}
          />

          <aside className={menuShow ? '' : 'asideClose'}>
            <ul>
              <li>
                <NavLink to={texts['linkHome'][lang]}>
                  {texts['navHome'][lang]}
                </NavLink>
              </li>
              <li>
                <NavLink to={texts['linkProjects'][lang]}>
                  {texts['navProjects'][lang]}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={texts['linkBlog'][lang] + '?s=&c=&pp=10&p=1&o=desc'}
                  onClick={(e) => handleBlogRemount()}
                >
                  {texts['navBlog'][lang]}
                </NavLink>
              </li>
              <li>
                <NavLink to={texts['linkContact'][lang]}>
                  {texts['navContact'][lang]}
                </NavLink>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/bruno-ravanello/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  className="whatsappIcon"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=5554991966476"
                  target="_blank"
                >
                  <FaWhatsapp className="whatsapp" />
                </a>
              </li>
              <li>
                {lightShow ? (
                  <FaLightbulb
                    className="moon"
                    onClick={() => handleLightShow()}
                  />
                ) : (
                  <FaRegLightbulb
                    className="sun"
                    onClick={() => handleLightShow()}
                  />
                )}
              </li>
            </ul>
          </aside>

          <Routes>
            <Route
              path={texts['linkHomeHome'][lang]}
              element={<Home wide={menuShow} lang={lang} />}
            />
            <Route
              path={texts['linkProjects'][lang]}
              element={
                <Projects
                  wide={menuShow}
                  lang={lang}
                  handleMenuHide={handleMenuHide}
                />
              }
            />
            <Route
              path={texts['linkBlog'][lang]}
              element={
                <Blog
                  wide={menuShow}
                  lang={lang}
                  handleMenuHide={handleMenuHide}
                  blogFilter={blogFilter}
                  setBlogFilter={setBlogFilter}
                  navBack={navBack}
                  setNavBack={setNavBack}
                  blogRemount={blogRemount}
                />
              }
            />
            <Route
              path={texts['linkBlog'][lang] + '/:slug'}
              element={
                <Post
                  wide={menuShow}
                  lang={lang}
                  blogFilter={blogFilter}
                  navBack={navBack}
                  setNavBack={setNavBack}
                  handleMenuHide={handleMenuHide}
                />
              }
            />
            <Route
              path={texts['linkContact'][lang]}
              element={
                <Contact
                  wide={menuShow}
                  lang={lang}
                  handleMenuHide={handleMenuHide}
                />
              }
            />
            <Route path="*" element={<Home wide={menuShow} lang={lang} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
