import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import axios from 'axios';
import { texts } from '../../texts';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton';
import Microphone from './Microphone';
import FadeIn from 'react-fade-in';
import 'react-loading-skeleton/dist/skeleton.css';
import './Blog.css';

function Blog(props) {
  const skeletons = [1, 2, 3, 4, 5];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const pp = urlParams.get('pp');
  const p = urlParams.get('p');
  const o = urlParams.get('o');

  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState(
    urlParams.get('s') ? urlParams.get('s') : ''
  );
  const [category, setCategory] = useState(
    urlParams.get('c') ? urlParams.get('c') : ''
  );
  const [skeletonShow, setSkeletonShow] = useState('block');
  const [noResultsShow, setNoResultsShow] = useState('none');
  const [loadingShow, setLoadingShow] = useState('none');
  const [perPage, setPerPage] = useState(pp && !isNaN(pp) ? pp : 10);
  const [page, setPage] = useState(p && !isNaN(p) ? p : 1);
  const [orderBy, setOrderBy] = useState(
    o === 'desc' || o === 'asc' ? o : 'desc'
  );
  const [pagesCount, setPagesCount] = useState(0);
  const [tableOpacity, setTableOpacity] = useState('0.3');
  const [filtersOpacity, setFiltersOpacity] = useState('0.3');
  const [gotFiltersCount, setGotFiltersCount] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchChange, setSearchChange] = useState(false);
  const [pageChange, setPageChange] = useState(false);

  function handleSearchMic(transcript) {
    setSearchChange(true);
    setPage(1);
    setSearch(transcript);
  }

  function handleSearch(e) {
    setSearchChange(true);
    setPage(1);
    setSearch(e.target.value);
  }

  function handleCategory(e) {
    setSearchChange(true);
    setPage(1);
    setCategory(e.target.value);
  }

  function handlePerPage(e) {
    setPageChange(true);
    setPage(1);
    setPerPage(e.target.value);
  }

  function handleOrderBy(e) {
    setOrderBy(e.target.value);
  }

  function handlePageClick(data) {
    setPageChange(true);
    setPage(data.selected + 1);
  }

  function startLoading() {
    setTableOpacity(0.3);
    setLoadingShow('inline-block');
  }

  function submitHandler(e) {
    e.preventDefault();
  }

  function stopLoading() {
    if (skeletonShow === 'block') {
      setSkeletonShow('none');
    }

    setTableOpacity(1);

    if (!gotFiltersCount) {
      setFiltersOpacity(1);
      const searchEl = document.getElementById('search');
      const categoryEl = document.getElementById('category');

      if (searchEl != null) {
        searchEl.disabled = false;
      }

      if (categoryEl != null) {
        categoryEl.disabled = false;
      }

      setGotFiltersCount(true);
    }

    setLoadingShow('none');
  }

  async function getPosts() {
    const apiPosts = 'https://api.brunoravanello.com.br/api/post';

    const storedFilter =
      '?s=' +
      search +
      '&c=' +
      category +
      '&pp=' +
      perPage +
      '&p=' +
      page +
      '&o=' +
      orderBy;

    if (!props.navBack) {
      window.history.replaceState('', '', storedFilter);
    } else {
      props.setNavBack(false);
    }

    props.setBlogFilter(storedFilter);

    const resPosts = await axios.get(apiPosts, {
      params: {
        search: search,
        category: category,
        page: page - 1,
        perPage: perPage,
        orderBy: orderBy,
      },
    });

    stopLoading();

    setNoResultsShow(resPosts.data.data.length < 1 ? 'block' : 'none');

    setPosts(resPosts.data.data);
    setPagesCount(Math.ceil(resPosts.data.count / perPage));
    window.scrollTo(0, 0);
  }

  async function getCategories() {
    setTimeout(function () {
      props.handleMenuHide();
    }, 300);

    const apiCategories = 'https://api.brunoravanello.com.br/api/category';

    const resCategories = await axios.get(apiCategories, {});

    setCategories(resCategories.data);
  }

  useEffect(() => {
    getCategories();

    startLoading();

    getPosts();

    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (firstLoad || pageChange) {
      return false;
    }

    startLoading();

    const delayDebounceFn = setTimeout(() => {
      getPosts();
    }, 800);

    setSearchChange(false);
    return () => clearTimeout(delayDebounceFn);
  }, [search, category]);

  useEffect(() => {
    if (firstLoad || searchChange) {
      return false;
    }

    startLoading();

    getPosts();

    setPageChange(false);
  }, [perPage, page, orderBy]);

  useEffect(() => {
    if (firstLoad) {
      return false;
    }

    setSearch('');
    setCategory('');
    setPerPage(10);
    setOrderBy('desc');
    setPage(1);
    startLoading();
    getPosts();
  }, [props.blogRemount]);

  return (
    <section className={'blog ' + (props.wide ? '' : 'sectionWide')}>
      <div
        style={{ maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <div className="loader" style={{ display: loadingShow }}>
          <div className="loaderBar"></div>
        </div>

        <h1 className="titleInside">Bruno Ravanello</h1>

        <div className="content">
          <FadeIn className="contentTitle">
            <h2>{texts['contentBlogTitle'][props.lang]}</h2>
          </FadeIn>

          <div className="table">
            <form
              className="filters"
              style={{ opacity: filtersOpacity }}
              onSubmit={(e) => {
                submitHandler(e);
              }}
            >
              <Microphone
                gotFiltersCount={gotFiltersCount}
                langRecording={texts['contentBlogRecording'][props.lang]}
                handleSearchMic={handleSearchMic}
              />
              <input
                id="search"
                disabled
                onChange={(e) => {
                  handleSearch(e);
                }}
                name="search"
                value={search}
                placeholder={texts['contentBlogSearchWord'][props.lang]}
              />
              <select
                id="category"
                disabled
                onChange={(e) => {
                  handleCategory(e);
                }}
                name="category"
                value={category}
              >
                <option value="">
                  {texts['contentBlogSearchCategory'][props.lang]}
                </option>
                {categories.length > 0 &&
                  categories.map((category) => (
                    <option key={category.id} value={category.slug}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </form>
          </div>

          <div className="table table2">
            <div className="tableHead">
              <div className="tableHeadSection">
                {texts['contentBlogPostTitle'][props.lang]}
              </div>
              <div className="tableHeadSection">
                {texts['contentBlogPostCategory'][props.lang]}
              </div>
              <div className="tableHeadSection headAccess"></div>
            </div>

            <center style={{ display: noResultsShow }}>
              <br />
              {texts['contentBlogNoResults'][props.lang]}
            </center>

            <div className="skeletonWrapp" style={{ display: skeletonShow }}>
              {skeletons.map((skeleton) => (
                <div key={skeleton} className="tableBody">
                  <div className="tableBodySection">
                    <Skeleton className="skeletonsBlog" />
                  </div>
                  <div className="tableBodySection">
                    <Skeleton className="skeletonsBlog" />
                  </div>
                  <div className="tableBodySection access skeletonAccess">
                    <a>{texts['contentBlogPostAccess'][props.lang]}</a>
                  </div>
                </div>
              ))}
            </div>

            {posts.length > 0 &&
              posts.map((post) => (
                <div key={post.id} className="tableBody">
                  <div
                    style={{ opacity: tableOpacity }}
                    className="tableBodySection"
                  >
                    {post.title}
                    <p className="smallDate">[{post.created_at}]</p>
                  </div>
                  <div
                    style={{ opacity: tableOpacity }}
                    className="tableBodySection"
                  >
                    {post.image ? (
                      <img
                        className="imgCategory"
                        src={
                          'https://api.brunoravanello.com.br/storage/' +
                          post.image
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div
                    style={{ opacity: tableOpacity }}
                    className="tableBodySection access"
                  >
                    <NavLink
                      lang={props.lang}
                      to={texts['linkBlog'][props.lang] + '/' + post.slug}
                    >
                      {texts['contentBlogPostAccess'][props.lang]}
                    </NavLink>
                  </div>
                </div>
              ))}

            {posts[0] && (
              <small className="pageInfo">
                {texts['contentBlogPageCurrent'][props.lang]} <b>{page}</b>{' '}
                {texts['contentBlogPageCount'][props.lang]} <b>{pagesCount}</b>
              </small>
            )}
          </div>
        </div>

        <div className="pages" style={{ opacity: tableOpacity }}>
          <ReactPaginate
            breakLabel="..."
            marginPagesDisplayed={2}
            nextLabel={<FaChevronRight className="pageArrows" />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pagesCount}
            previousLabel={<FaChevronLeft className="pageArrows" />}
            renderOnZeroPageCount={null}
            forcePage={page - 1}
          />
        </div>

        {posts[0] && (
          <div className="bottomSelects" style={{ opacity: tableOpacity }}>
            <label> {texts['contentBlogPerPage'][props.lang]} </label>
            <select
              value={perPage}
              name="perPage"
              onChange={(e) => {
                handlePerPage(e);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        )}

        {posts[0] && (
          <div className="bottomSelects" style={{ opacity: tableOpacity }}>
            <label> {texts['contentBlogOrderBy'][props.lang]} </label>
            <select
              value={orderBy}
              name="orderBy"
              onChange={(e) => {
                handleOrderBy(e);
              }}
            >
              <option value="desc">Mais recentes</option>
              <option value="asc">Mais antigas</option>
            </select>
          </div>
        )}
      </div>
    </section>
  );
}

export default Blog;
