import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FaCode, FaShoppingCart, FaMobileAlt, FaCogs } from 'react-icons/fa';
import FadeIn from 'react-fade-in';
import { texts } from '../../texts';
import './Home.css';
import logo from '../../logo2.png';
import php from '../../techs/php.png';
import laravel from '../../techs/laravel.png';
import codeigniter from '../../techs/codeigniter.png';
import cakephp from '../../techs/cakephp.png';
import javascript from '../../techs/javascript.png';
import node from '../../techs/node-js.png';
import vuejs from '../../techs/vuejs.png';
import react from '../../techs/react.png';
import mysql from '../../techs/mysql.png';
import postgresql from '../../techs/postgresql.png';
import sql from '../../techs/sql.png';
import html5 from '../../techs/html5.png';
import css3 from '../../techs/css3.png';
import sass from '../../techs/sass.png';
import less from '../../techs/less.png';
import docker from '../../techs/docker.png';
import git from '../../techs/git.png';

function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={'home ' + (props.wide ? '' : 'sectionWide')}>
      <div
        style={{ maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <div className="bgSec"></div>

        <div className="realSec">
          <div className="logoWrapp">
            <img src={logo} className="appLogo" alt="logo" />
          </div>

          <div className="content">
            <FadeIn>
              <h2>{texts['contentHome1'][props.lang]}</h2>
            </FadeIn>

            <p>{texts['contentHome2'][props.lang]}</p>
            <Link className="linkContact" to={texts['linkContact'][props.lang]}>
              {texts['contentHome3'][props.lang]}
            </Link>
          </div>

          <div className="homeDivs">
            <h3 className="servicesTitle">
              {texts['contentHome4'][props.lang]}
            </h3>

            <FadeIn className="servicesContainer">
              <FaMobileAlt className="servicesImg" />
              <p>{texts['contentHome5'][props.lang]}</p>
            </FadeIn>

            <FadeIn className="servicesContainer">
              <FaCogs className="servicesImg" />
              <p>{texts['contentHome6'][props.lang]}</p>
            </FadeIn>

            <FadeIn className="servicesContainer">
              <FaShoppingCart className="servicesImg" />
              <p>{texts['contentHome7'][props.lang]}</p>
            </FadeIn>

            <FadeIn className="servicesContainer">
              <FaCode className="servicesImg" />
              <p>{texts['contentHome8'][props.lang]}</p>
            </FadeIn>
          </div>

          <div className="homeDivs">
            <hr />

            <div className="techs">
              <img src={javascript} alt="JavaScript" />
              <img src={node} alt="Node.js" />
              <img src={vuejs} alt="Vue.js" />
              <img src={react} alt="React.js" />
              <img src={php} alt="PHP" />
              <img src={laravel} alt="Laravel" />
              <img src={codeigniter} alt="Codeigniter" />
              <img src={cakephp} alt="cakePHP" />
              <img src={mysql} alt="MySQL" />
              <img src={postgresql} alt="PostgreSQL" />
              <img src={sql} alt="SQL Server" />
              <img src={html5} alt="HTML" />
              <img src={css3} alt="CSS" />
              <img src={docker} alt="Docker" />
              <img src={git} alt="Git" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
