import React, { useEffect } from 'react';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

const Microphone = (props) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (transcript) {
      props.handleSearchMic(transcript);
    }
  }, [transcript]);

  return (
    <div
      style={{ display: browserSupportsSpeechRecognition ? 'block' : 'none' }}
      className="microphone"
    >
      {listening && (
        <div className="recording">
          <FaMicrophone /> {props.langRecording}
        </div>
      )}
      {!listening && props.gotFiltersCount ? (
        <div className="micButton" onClick={SpeechRecognition.startListening}>
          <FaMicrophone />
        </div>
      ) : (
        <div className="micButton" onClick={resetTranscript}>
          <FaMicrophoneSlash />
        </div>
      )}
    </div>
  );
};

export default Microphone;
